import { useState } from 'react';
import ImageUploader from 'react-images-upload';
import BottomBar from './BottomBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

function App() {
    const [targetRes, setTargetRes] = useState('200');
    const [waiting, setWaiting] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [faceFileInfos, setFaceFileInfos] = useState([]);
    const [downloadFilePath, setDownloadFilePath] = useState(null);

    const onDrop = (selectedPictures) => {
        setPictures(selectedPictures);
    };

    const uploadAndConvert = async () => {
        setFaceFileInfos([]);
        setDownloadFilePath(null);
        setWaiting(true);

        const url =
            'https://facecrop.hanmesoft.com/facecrop_server/convert.php';

        const formData = new FormData();
        for (let picture of pictures) {
            //console.log(picture);
            formData.append('userpic[]', picture, picture.name);
        }
        formData.append('targetRes', targetRes);

        const result = await axios.post(url, formData);
        const data = result.data;

        //console.log('data:');
        //console.log(data);

        setFaceFileInfos(data.faceFileInfos);
        setDownloadFilePath(data.downloadFilePath);

        setWaiting(false);
    };

    const cropPictures = (e) => {
        uploadAndConvert();
    };

    //console.log('pictures:');
    //console.log(pictures);
    //console.log('faceFileInfos:');
    //console.log(faceFileInfos);

    const convertButtonMessage = waiting
        ? '변환중입니다'
        : faceFileInfos.length > 0
        ? '다시 변환하기'
        : '변환하기';

    const conversionFailCount = faceFileInfos.filter(
        (value) => !value.success
    ).length;

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://facecrop.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                얼굴사진 만들기
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <p>
                            직장이나 동호회 등에서 조직표라든가 출석표 등을
                            만들어야 할때, 구성원들이 제출한, 때로는 너무
                            캐주얼한 인물 사진에서 얼굴 부분만 잘라내어 깔끔하게
                            규격화된 얼굴 사진을 쉽고 편리하게 만들 수 있습니다.
                        </p>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-1 col-md-1 col-xl-1 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-10 col-xl-10 m-0 py-0 px-3">
                        <ImageUploader
                            withPreview={true}
                            withIcon={true}
                            buttonText={'이미지 선택'}
                            onChange={onDrop}
                            withLabel={true}
                            label={'이미지 파일을 선택해 주세요.'}
                            imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                            maxFileSize={10485760}
                        />
                        <div
                            className="mx-auto"
                            style={{
                                maxWidth: '266px',
                            }}
                        >
                            <Form.Control
                                as="select"
                                className="mx-auto"
                                value={targetRes}
                                onChange={(e) => {
                                    //console.log(
                                    //    'e.target.value',
                                    //    e.target.value
                                    //);
                                    setTargetRes(e.target.value);
                                }}
                                style={{
                                    textAlign: 'center',
                                    textAlignLast: 'center',
                                }}
                            >
                                <option value="100">해상도: 100 x 100</option>
                                <option value="150">해상도: 150 x 150</option>
                                <option value="200">해상도: 200 x 200</option>
                                <option value="250">해상도: 250 x 250</option>
                                <option value="300">해상도: 300 x 300</option>
                                <option value="0">원본 크기</option>
                            </Form.Control>
                        </div>
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={cropPictures}
                            disabled={pictures.length <= 0 || waiting}
                            style={{
                                minWidth: '266px',
                                minHeight: '60px',
                            }}
                        >
                            {convertButtonMessage}
                        </Button>
                        <div className="m-2">
                            <ClipLoader
                                color={'teal'}
                                loading={waiting}
                                size={51}
                            />
                        </div>
                        {faceFileInfos.length > 0 && (
                            <Card bg="light" className="shadow m-2 p-0">
                                <Card.Header as="h4">
                                    변환된 얼굴사진
                                </Card.Header>
                                <Card.Body className="m-2 p-3">
                                    {conversionFailCount > 0 && (
                                        <div className="row title text-center">
                                            <div className="col-12 m-0 p-0">
                                                <h4 className="text-danger">
                                                    {conversionFailCount}개의
                                                    이미지 변환에 실패했습니다.
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row row-cols-4 justify-content-center">
                                        {faceFileInfos.map((value, index) => {
                                            return (
                                                <img
                                                    key={index}
                                                    src={
                                                        'https://facecrop.hanmesoft.com/facecrop_server/' +
                                                        value.outputFilePath
                                                    }
                                                    className="m-0 p-1"
                                                    style={{
                                                        border: value.success
                                                            ? '5px solid #f8f9fa'
                                                            : '5px solid #dc3545',
                                                        borderRadius: '15%',
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Button
                                        href={
                                            'https://facecrop.hanmesoft.com/facecrop_server/' +
                                            downloadFilePath
                                        }
                                        variant="primary"
                                        className="mt-2"
                                    >
                                        모든 사진 다운로드
                                    </Button>
                                </Card.Body>
                            </Card>
                        )}
                    </div>
                    <div className="col-sm-1 col-md-1 col-xl-1 m-0 p-0"></div>
                </div>
            </div>
            <BottomBar />
        </>
    );
}

export default App;
